import server1 from "../assets/img/10x.png";
import server2 from "../assets/img/2x.png";
import server3 from "../assets/img/quad.png";
import splits_circle from "../assets/img/splits_circle.png";
import soon from "../assets/img/soon.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Servers</h2>
                        <p>Check out some of our current servers. We're confident we can satisfy your needs.<br></br> We take pride in quality, moderated servers.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                              <a href="steam://connect/148.59.74.10:28014">
                                <img src={server1} alt="2x Fridays Image" />
                                <h5>2x Vanilla Fridays</h5>
                              </a>
                            </div>
                            <div className="item">
                              <a href="steam://connect/45.88.228.193:28014">
                                <img src={server2} alt="2x Mondays Image" />
                                <h5>2x Vanilla Mondays</h5>
                              </a>
                            </div>
                            <div className="item">
                              <a href="https://splits.gg/" target="_blank">
                                <img src={splits_circle} alt="Splits Logo Circle" />
                                <h5>Check out Splits.gg</h5>
                              </a>
                            </div>
                            <div className="item">
                              <a href="steam://connect/104.143.3.60:28014" target="_blank">
                                <img src={server3} alt="2x Quad Image" />
                                <h5>2x Quad</h5>
                              </a>
                            </div>
                            {/* <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>Web Development</h5>
                            </div> */}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        {/* <img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  )
}
