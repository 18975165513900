import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg1 from "../assets/img/header-img.png"; // First image
import headerImg2 from "../assets/img/splits_small.png"; // Second image
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const [currentImage, setCurrentImage] = useState(headerImg1);
  const [isFading, setIsFading] = useState(false); // New state for fading effect
  const toRotate = [ "modded servers!", "vanilla servers!" ];
  const period = 2000;
  const imageChangeInterval = 5000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, 175);

    return () => { clearInterval(ticker) };
  }, [text]);

  useEffect(() => {
    const imageTicker = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentImage((prevImage) => prevImage === headerImg1 ? headerImg2 : headerImg1);
        setIsFading(false);
      }, 1000); // The duration of the fade-out before the image switches
    }, imageChangeInterval);

    return () => clearInterval(imageTicker);
  }, []);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Welcome to Rust in Peace</span>
                <h1>{`Fun`} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "modded servers!", "vanilla servers!" ]'><span className="wrap">{text}</span></span></h1>
                  <p>Our servers combine all the features you would expect from a modded server, and adds unique events, along with more quality of life features. We want our servers to stand out, and give you an enjoyable playing experience. With active non-playing staff, we want you to enjoy your time.</p>
                    <a href="https://link.rustinpeace.co">
                      <button onClick={() => console.log('connect')}>Link your Account <ArrowRightCircle size={25} /></button>
                    </a>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <a href="https://splits.gg/" target="_blank">
                    <img 
                      src={currentImage} 
                      alt="Header Img" 
                      className={isFading ? 'fade-in' : ''} // Toggle the fade-in class
                      style={{ width: '100%', height: '460px', display: 'block' }}
                    />
                  </a>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
