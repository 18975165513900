import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import splits_banner from "../assets/img/splits_landscape.png";
import navIcon1 from '../assets/img/tiktok.svg';
import navIcon2 from '../assets/img/youtube.svg';
import navIcon3 from '../assets/img/twitter.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
          <a href="https://splits.gg/" target="_blank">
            <img src={splits_banner} alt="Splits.gg Banner" style={{width: "100%"}}/>
          </a>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.tiktok.com/@rustinpeaceservers?lang=en" target="_blank"><img src={navIcon1} alt="Tiktok Icon" /></a>
              <a href="https://www.youtube.com/channel/UCdpD53XDM7ALErtEiLl3NUQ" target="_blank"><img src={navIcon2} alt="YouTube Icon" /></a>
              <a href="https://twitter.com/playrustinpeace" target="_blank"><img src={navIcon3} alt="X Icon" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
