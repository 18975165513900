import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo-circle.png';
import navIcon1 from '../assets/img/tiktok.svg';
import navIcon2 from '../assets/img/youtube.svg';
import navIcon3 from '../assets/img/twitter.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="https://store.rustinpeace.co/" className={activeLink === 'store' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('store')}>Store</Nav.Link>
              <Nav.Link href="https://discord.gg/rustinpeace" className={activeLink === 'discord' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('discord')}>Discord</Nav.Link>
              {/* <Nav.Link href="#projects" className={activeLink === 'leaderboard' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('leaderboard')}>Leaderboards</Nav.Link> */}
              {/* <Nav.Link href="https://link.rustinpeace.co/" className={activeLink === 'link-account' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('link-account')}>Link Account</Nav.Link> */}
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.tiktok.com/@rustinpeaceservers" target="_blank"><img src={navIcon1} alt="Icon" /></a>
                <a href="https://www.youtube.com/channel/UCdpD53XDM7ALErtEiLl3NUQ" target="_blank"><img src={navIcon2} alt="Icon" /></a>
                <a href="https://twitter.com/playrustinpeace" target="_blank"><img src={navIcon3} alt="Icon" /></a>
              </div>
              <a href="https://link.rustinpeace.co/">
                <button className="vvd"><span>Link Account</span></button>
              </a>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
